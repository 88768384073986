
// Pure CSS, thanks https://github.com/abpetkov/switchery/issues/13
.switch input {
    display: none;
}
.switch i {
    display: inline-block;
    cursor: pointer;
    padding-right: 25px;
    transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    border-radius: 30px;
    box-shadow: inset 0 0 1px rgba(0,0,0,.5) ;
}
.switch i:before {
    display: block;
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background: white;
    box-shadow: 0 1px 2px rgba(0,0,0,.5) ;
}
.switch :checked + i {
    padding-right: 0;
    padding-left: 25px;
    box-shadow: inset 0 0 1px rgba(0,0,0,.5), inset 0 0 50px $brand-primary ;
}

.switch.switch-success :checked + i {
    box-shadow: inset 0 0 1px rgba(0,0,0,.5), inset 0 0 50px $brand-success ;
}

.switch.switch-info :checked + i {
    box-shadow: inset 0 0 1px rgba(0,0,0,.5), inset 0 0 50px $brand-info ;
}

.switch.switch-warning :checked + i {
    box-shadow: inset 0 0 1px rgba(0,0,0,.5), inset 0 0 50px $brand-warning ;
}

.switch.switch-danger :checked + i {
    box-shadow: inset 0 0 1px rgba(0,0,0,.5), inset 0 0 50px $brand-danger ;
}

