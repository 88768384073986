// disable for moble
.introjs-overlay,
.introjs-helperLayer {
    display: none;
    @media (min-width: $screen-sm) {
        display: block;
    }
}

.introjs-overlay {
    position: absolute;
    z-index: 999999;
    background-color: #000;
    opacity: 0;
    background: -moz-radial-gradient(center,ellipse farthest-corner,rgba(0,0,0,0.6) 0,rgba(0,0,0,0.9) 100%);
    background: -webkit-gradient(radial,center center,0px,center center,100%,color-stop(0%,rgba(0,0,0,0.6)),color-stop(100%,rgba(0,0,0,0.9)));
    background: -webkit-radial-gradient(center,ellipse farthest-corner,rgba(0,0,0,0.6) 0,rgba(0,0,0,0.9) 100%);
    background: -o-radial-gradient(center,ellipse farthest-corner,rgba(0,0,0,0.6) 0,rgba(0,0,0,0.9) 100%);
    background: -ms-radial-gradient(center,ellipse farthest-corner,rgba(0,0,0,0.6) 0,rgba(0,0,0,0.9) 100%);
    background: radial-gradient(center,ellipse farthest-corner,rgba(0,0,0,0.6) 0,rgba(0,0,0,0.9) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000',endColorstr='#e6000000',GradientType=1);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    @include transition( all 0.3s ease-out );
}

.introjs-fixParent {
    z-index: auto !important;
    opacity: 1.0 !important;
}

.introjs-showElement {
    z-index: 9999999 !important;
}

.introjs-relativePosition {
    position: relative;
}

.introjs-helperLayer {
    position: absolute;
    z-index: 9999998;
    background: no;
    border: 2px solid #f5f5f5;
    border-radius: $border-radius-base;
    box-shadow: 0 2px 15px rgba(0,0,0,.4);
    @include transition( all 0.3s ease-out );
}

.introjs-helperNumberLayer {
    display: none;
    position: absolute;
    top: -10px;
    left: -10px;
    z-index: 9999999999 !important;
    padding: 2px;
    font-size: 13px;
    color: white;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0,0,0,.3);
    background: $brand-danger;
    width: 20px;
    height:20px;
    line-height: 18px;
    border-radius: 50%;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3019', endColorstr='#cf0404', GradientType=0); /* IE6-9 */
    filter: progid:DXImageTransform.Microsoft.Shadow(direction=135, strength=2, color=ff0000); /* IE10 text shadows */
    box-shadow: 0 2px 5px rgba(0,0,0,.4);
}

.introjs-arrow {
    border: 5px solid white;
    content:'';
    position: absolute;
}
.introjs-arrow.top {
    top: -10px;
    border-top-color:transparent;
    border-right-color:transparent;
    border-bottom-color:white;
    border-left-color:transparent;
}
.introjs-arrow.right {
    right: -10px;
    top: 10px;
    border-top-color:transparent;
    border-right-color:transparent;
    border-bottom-color:transparent;
    border-left-color:white;
}
.introjs-arrow.bottom {
    bottom: -10px;
    border-top-color:white;
    border-right-color:transparent;
    border-bottom-color:transparent;
    border-left-color:transparent;
}
.introjs-arrow.left {
    left: -10px;
    top: 10px;
    border-top-color:transparent;
    border-right-color:white;
    border-bottom-color:transparent;
    border-left-color:transparent;
}

.introjs-tooltip {
    position: absolute;
    padding: 10px;
    background-color: white;
    min-width: 200px;
    max-width: 300px;
    border-radius: 3px;
    box-shadow: 0 1px 10px rgba(0,0,0,.4);
    @include transition( opacity 0.1s ease-out );
}

.introjs-tooltipbuttons {
    text-align: right;
}

/*
 Buttons style by http://nicolasgallagher.com/lab/css3-github-buttons/
 Changed by Afshin Mehrabani
*/
.introjs-button {
    position: relative;
    overflow: visible;
    display: inline-block;
    padding: 0.3em 0.8em;
    border: 1px solid #d4d4d4;
    margin: 0;
    text-decoration: none;
    text-shadow: 1px 1px 0 #fff;
    font: 11px/normal sans-serif;
    color: #333;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    background-color: #ececec;
    -webkit-background-clip: padding;
    -moz-background-clip: padding;
    -o-background-clip: padding-box;
    /*background-clip: padding-box;*/ /* commented out due to Opera 11.10 bug */
    border-radius: 0.2em;
    /* IE hacks */
    zoom: 1;
    margin-top: 10px;
}

.introjs-button:hover {
    border-color: #bcbcbc;
    text-decoration: none;
    box-shadow: 0px 1px 1px #e3e3e3;
}

.introjs-button:focus,
.introjs-button:active {
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ececec), to(#f4f4f4));
    background-image: -moz-linear-gradient(#ececec, #f4f4f4);
    background-image: -o-linear-gradient(#ececec, #f4f4f4);
    background-image: linear-gradient(#ececec, #f4f4f4);
}

/* overrides extra padding on button elements in Firefox */
.introjs-button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.introjs-skipbutton {
    margin-right: 5px;
    color: #7a7a7a;
}

.introjs-prevbutton {
    border-radius: 0.2em 0 0 0.2em;
    border-right: none;
}

.introjs-nextbutton {
    border-radius: 0 0.2em 0.2em 0;
}

.introjs-disabled, .introjs-disabled:hover, .introjs-disabled:focus {
    color: #9a9a9a;
    border-color: #d4d4d4;
    box-shadow: none;
    cursor: default;
    background-color: #f4f4f4;
    background-image: none;
    text-decoration: none;
}

.introjs-bullets {
    text-align: center;
}
.introjs-bullets ul {
    clear: both;
    margin: 15px auto 0;
    padding: 0;
    display: inline-block;
}
.introjs-bullets ul li {
    list-style: none;
    float: left;
    margin: 0 2px;
}
.introjs-bullets ul li a {
    display: block;
    width: 6px;
    height: 6px;
    background: #ccc;
    border-radius: 10px;
    text-decoration: none;
}
.introjs-bullets ul li a:hover {
    background: #999;
}
.introjs-bullets ul li a.active {
    background: #999;
}
.introjsFloatingElement {
    position: absolute;
    height: 0;
    width: 0;
    left: 50%;
    top: 50%;
}
