
@media (max-width: $screen-sm-max ) { // make '.open' class effective mobile only
  .nav-horizontal {
      .nav {
          > li {
              &.open {
                  > a {
                      background-color: darken($sidebar_bg, 5%);
                      color: $brand-primary;
                  }
                  > .fa { color: $brand-primary; }
              }

          }
      }
  }
}


// Horizontal nav is desktop only
@media (min-width: $screen-sm) {

  .main-container {

      > dash-sidenav > .nav-container.nav-horizontal {
          float: none;
          width: auto;

          // content
          & + .content-container {
              margin-left: 0;
          }
      }
  }

  .nav-horizontal {

      .slimScrollDiv {
          overflow: visible !important; // overrides slimScroll plugin
          height: auto !important;

          .slimScrollBar,
          .slimScrollRail {
              display: none !important;
          }
      }

      .nav {
          overflow: visible !important;

          > li {
              position: relative;
              float: left;
              > a {
                  padding: 15px 30px;
                  text-align: center;
                  font-weight: normal;

                  > .fa {
                      margin: 0;
                      font-size: 26px;
                      line-height: 1;
                  }

                  span {
                      margin: 0;
                      display: block;
                      font-size: 12px;
                  }

                  .badge {
                      top: 15px;
                  }
              }

              > .icon-has-ul-h { // icon for horizontal nav
                  display: block;
                  position: absolute;
                  bottom: 16px;
                  right: 10px;
              }

              > .icon-has-ul { // icon for default vertical nav
                  display: none;
              }

              &:hover {
                  > ul {
                      display: block !important;
                  }
              }



              // sub menu
              > ul {
                  z-index: 9; // less then header, so that it's below header dropdown
                  position: absolute;
                  left: 0;
                  top: 100%;
                  min-width: 100%;
                  width: auto;

                  li {
                      white-space: nowrap;
                      > a {
                          padding: 13px 40px 13px 20px;
                      }
                  }
              }
          }
      }
  }


}
