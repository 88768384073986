
@mixin bg-style ($background, $color) {
    background-color: $background;
    color: $color;
}

@mixin bg-variant-alt ($color) {
    background-color: $color;
    color: $reverse;
    &:hover {
        background-color: $color;
        color: $reverse;
    }
}

body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

::selection {
    background: $brand-primary;
    color: $reverse;
}
::-moz-selection {
    background: $brand-primary;
    color: $reverse;
}

a {
    text-decoration: none;
    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
    }
}

span.block {
    display: block;
}

.no-margin {
    margin: 0;
}
.no-border {
    border: 0;
}

// background
// -----------------
// overrides Bootstrap
a.bg-primary { @include bg-variant-alt ($brand-primary); }
a.bg-success { @include bg-variant-alt ($brand-success); }
a.bg-info { @include bg-variant-alt ($brand-info); }
a.bg-warning { @include bg-variant-alt ($brand-warning); }
a.bg-danger { @include bg-variant-alt ($brand-danger); }
a.bg-orange { @include bg-variant-alt ($color-orange); }

// normal
.bg-primary { @include bg-style($brand-primary, $reverse) };
.bg-primary-light { @include bg-style($skin-light, $reverse) };
.bg-success { @include bg-style($brand-success, $reverse) };
.bg-info { @include bg-style($brand-info, $reverse) };
.bg-warning { @include bg-style($brand-warning, $reverse) };

.bg-danger { @include bg-style($brand-danger, $reverse) };
.bg-orange { @include bg-style($color-orange, $reverse) };
.bg-violet { @include bg-style($color-violet, $reverse) };
.bg-dark { @include bg-style($dark, $reverse) };
.bg-bright { @include bg-style($bright, $text-color) };
.bg-reverse { @include bg-style($reverse, $text-color) };

.bg-facebook { @include bg-style(#335397, $reverse) };
.bg-twitter { @include bg-style(#00c7f7, $reverse) };
.bg-google-plus { @include bg-style(#df4a32, $reverse) };
