// overrides Bootstrap style
.progress {
    box-shadow: none;
    background-color: $pagination-bg;
}

.progress-rounded {
    border-radius: 50px;
}
.progress-bar {
    box-shadow: none;
}

// custom
.progressbar-xs { height: 10px; }
.progressbar-sm { height: 15px; }