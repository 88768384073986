.angular-ui-tree {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 20px;
    list-style: none;

}

.angular-ui-tree-placeholder{
    margin: 5px 0;
    padding: 0;
    min-height: 30px;
    border: 1px dashed $state-default-border;
    background-color: $state-info-bg;
    border-radius: $border-radius-base;
}

.angular-ui-tree-handle{
    position: relative;
    display: block; 
    margin: 5px 0; 
    padding: 10px 10px; 
    text-decoration: none; 
    border: 1px solid $state-default-border;
    background: #fff;
    cursor:move;
    border-radius: $border-radius-base;

    &:hover {
        .angular-ui-tree-icon-action {
            display: inline;
        }
    }
}


.angular-ui-tree-icon {
    &:hover {
        cursor: pointer;
    }
}
.angular-ui-tree-icon-collapse {
    display: block;
    position: relative;
    cursor: pointer;
    float: left;
    width: 25px;
    height: 40px;
    margin: -10px 0 0 -10px;
    padding: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    background: transparent;
    font-size: 12px;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    &:before {
        content: '+'; display: block; position: absolute; width: 100%; text-align: center; text-indent: 0;
    }
    &.uncollapsed:before {
        content: '-';
    }
}
.angular-ui-tree-icon-action {
    margin-left: 7px;
    display: none;
    color: $gray-light;
    @include transform(scale(1));
    @include transition(all .2s ease-in-out);
    &:hover {
        @include transform(scale(1.3));
        color: $text-color;
    }
}

.angular-ui-tree-empty{
    border: 1px dashed #bbb;
    min-height: 100px;
    background-color: #fff;
    background-size:60px 60px;
    background-position:0 0,30px 30px;
}

.angular-ui-tree-nodes{
    display:block;
    position:relative;
    margin:0;
    padding:0;
    list-style:none}

.angular-ui-tree-nodes .angular-ui-tree-nodes{
    padding-left: 30px
}

.angular-ui-tree-node,.angular-ui-tree-placeholder{
    display:block;
    position:relative;
    margin:0;
    padding:0;
    min-height:20px;
    line-height:20px}

.angular-ui-tree-hidden{display:none}




.angular-ui-tree-drag{
    position:absolute;
    pointer-events:none;
    z-index:999;
    opacity:.8}
