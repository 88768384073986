body {
  background: url('/assets/images/bg-pattern/bg-pattern4.png') repeat #fff;
}

// fullscreen, hide header and sidebar
.body-wide {
  #header,
  #nav-container {
    display: none;
  }

  &.main-container {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }

  #content {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
  }

  // hide intro
  .introjs-overlay,
  .introjs-helperLayer {
    display: none;
  }
}
// lock screen
.body-lock {
  #content {
    background: url('/images/background/5.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
