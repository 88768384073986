@media (min-width: $screen-sm) {

    body>dash-root .nav-collapsed-min {

        // default
        .slimScrollDiv {
            overflow: visible !important; // overrides slimScroll plugin
            height: auto !important;

            .slimScrollBar,
            .slimScrollRail {
                display: none !important;
            }
        }

        #nav-container {
            width: 60px;
        }

        #content {
            margin-left: 60px;
        }

        #nav-wrapper {
            overflow: visible !important;
        }
        #nav {
            overflow: visible !important;
            > li {
                // width: 50px;
                > a {
                    padding: 15px 15px;
                    text-align: center;

                    > i {
                        margin: 0;
                        font-size: 20px;
                    }
                    > span {
                        display: none;
                    }
                }

                .icon-has-ul {
                    display: none;
                }

                > ul {
                    z-index: 9;
                    position: absolute;
                    left: 100%;
                    top: 0;
                    width: 220px;
                    @include border-right-radius($border-radius-base);
                    box-shadow: 1px 1px 3px rgba(0,0,0,.2);
                }

                &:hover,
                &.open {
                    > ul {
                        display: block !important;
                    }
                }
            }
        }


        // Horizontal menu
        .nav-horizontal {
            &#nav-container {
                width: auto;
            }

            & + #content {
                margin: 0;
            }

            &.nav-fixed + #content {
                margin: 50px 0 0;
            }

            #nav {
                > li {
                    > .fa {
                        display: none;
                    }

                    > ul {
                        left: 0;
                        top: 100%;
                    }
                }
            }
        }

    }
}
