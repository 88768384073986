@import 'assets/theme/global/var';

.ui-tree-empty {
  text-align: center;
  font-size: 14px;
  line-height: 100px;
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #fff;
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

.tr-clickable {
  cursor: pointer;
}

.da-panel-heading {
  overflow: auto;
}
.au-panel-heading {
  overflow: auto;
}
.au-img {
  width: 112px;
  height: 63px;
}

agm-map {
  height: 700px;
}

.of-auto {
  overflow: auto;
}
.p-relative {
  position: relative;
}

.auadd-title {
  font-size: 20px;
  margin-bottom: 13px;
  margin-left: 5px;
  display: inline-block;
}
.editable-click,
a.editable-click {
  text-decoration: none;
  color: #428bca;
  border-bottom: dashed 1px #428bca;
}
.editable-click:hover,
a.editable-click:hover {
  text-decoration: none;
  color: #2a6496;
  border-bottom-color: #2a6496;
}

.btn-group {
  .btn-primary,
  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary.focus,
  .btn-primary:active {
    background-color: white;
    color: $brand-primary;
    border-color: $brand-primary;
    &.active {
      color: #fff;
      background-color: $brand-primary;
      border-color: $brand-primary;
    }
  }
}

// Multiselect
.multiselect-dropdown {
  .dropdown-btn {
    .selected-item {
      background-color: $brand-primary !important;
      border: 1px solid $brand-primary !important;
      margin-bottom: 4px;
    }
  }
}
.multiselect-item-checkbox {
  input[type='checkbox'] {
    &:checked {
      & + div:before {
        background-color: $brand-primary !important;
      }
    }
    & + div:before {
      color: $brand-primary !important;
      border: 2px solid $brand-primary !important;
    }
  }
}
