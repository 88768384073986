// https://github.com/manifestinteractive/jqvmap
.jqvmap-label {
    position: absolute;
    display: none;
    border-radius: $border-radius-small;
    background: $dark;
    color: #fff;
    font-size: smaller;
    padding: 3px;
}
.jqvmap-zoomin, .jqvmap-zoomout {
    position: absolute;
    left: 10px;
    border-radius: $border-radius-small;
    background: $dark;
    padding: 3px;
    color: #fff;
    cursor: pointer;
    line-height: 10px;
    text-align: center;
}
.jqvmap-zoomin {
    top: 10px;
}
.jqvmap-zoomout {
    top: 30px;
}
.jqvmap-region {
  cursor: pointer;
}
.jqvmap-ajax_response {
  width: 100%;
  height: 500px;
}