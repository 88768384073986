
/*==========  animations  ==========*/
// Thanks, https://github.com/daneden/animate.css

// animate-fade-up, a combination of "fadeOutUp" and "fadeInUp"
// html { // fix webkit translate causing scollbar issue, side effect: page fail to scroll
//   overflow: hidden;
// }
@include keyframes(fade-up-enter) {
    0% {
        opacity: 0;
        @include transform( translateY(20px) );
    }
    
    100% {
        opacity: 1;
        @include transform( translateY(0) );
    }
}
@include keyframes(fade-up-leave) {
    0% {
        opacity: 1;
        @include transform( translateY(0) );
    }
    
    100% {
        opacity: 0;
        @include transform( translateY(-20px) );
    }
}
.animate-fade-up.ng-enter,
.animate-fade-up.ng-leave {
}
.animate-fade-up.ng-enter {
    @include animation(0.35s fade-up-enter);
}
// .animate-fade-up.ng-leave {
//     @include animation(0.35s fade-up-leave);
// }

// animate-flip-y, a combination of "flipInY" and "flipOutY"
@include keyframes(animate-flip-y-enter) {
    0% {
        @include transform( perspective(3000px) rotateY(90deg) );
        opacity: 0;
    }
    100% {
        @include transform( perspective(3000px) rotateY(0deg) );
        opacity: 1;
    }

}
@include keyframes(animate-flip-y-leave) {
    0% {
        @include transform( perspective(3000px) rotateY(0deg) );
        opacity: 1;
    }
    100% {
        @include transform( perspective(3000px) rotateY(-90deg) );
        opacity: 0;
    }
}
.animate-flip-y.ng-enter {
    @include animation(0.5s animate-flip-y-enter);
}
.animate-flip-y.ng-leave {
    @include animation(0.5s animate-flip-y-leave);
}


// vertical slide
@include keyframes(slideInDown) {
    0% {
        opacity: 0;
        @include transform( translateY(-2000px) );
    }

    100% {
        @include transform( translateY(0) );
    }
}
@include keyframes (slideOutUp) {
    0% {
        @include transform( translateY(0) );
    }

    100% {
        opacity: 0;
        @include transform( translateY(-2000px) );
    }
}
.animate-vertical-slide.ng-hide-add {
    @include animation(.35s slideOutUp ease-in-out);
}
.animate-vertical-slide.ng-hide-remove {
    @include animation(.35s .35s slideInDown ease-in-out);
}
