$sub_nav_bg:            $sidebar_bg;
$link_color:            $text-color;
$link_active_bg:        $brand-primary;

#nav-container {
    ul {
        padding-left: 0;
        list-style: none;
    }

    > .nav-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
    }
}

// Vertical NAV only
.nav-vertical {
    .nav {
        > li {
            &.open {
                > a {
                    background-color: darken($sidebar_bg, 5%);
                    color: $brand-primary;
                }
                > .fa { color: $brand-primary; }
            }

        }
    }
}

.nav-container {
    .nav {
        margin: 0;
        padding: 0;
        -webkit-overflow-scrolling: touch;
        -webkit-overflow-scrolling: -blackberry-touch;

        >li>a>i,
        >li>a>.nav-icon {
            display: inline-block;
            margin-right: 10px;
            width: 20px;
            line-height: 1;
            text-align: center;
            font-size: 16px;
            @include transition(all .2s ease-in-out);
        }

        a {
            font-size: 13px;
            color: $link_color;
            @include transition(all .2s ease-in-out);
            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
            }

            &.static {
				background-color: darken($sidebar_bg, 20%);;
				font-size: 13px;
				color: white;
        @include transition(all .2s ease-in-out);
				&:hover {
					background-color: darken($sidebar_bg, 20%);;
					text-decoration: none;
					color: white;
				}
			}
        }

        >li {
            position: relative;
            margin: 0;
            text-align: left;
            font-weight: bold;
            @include transition( all .2s ease-in-out);
            &:hover {
                > a {
                    background-color: darken($sidebar_bg, 5%);
                    color: $brand-primary;
                }
                > .fa { color: $brand-primary; }
            }
            &.active {
                > a {
                    background-color: $link_active_bg;
                    color: $bright;
                }
                > .fa { color: $bright; }
            }

            &:first-child > a {
                border-top: 0;
            }
            >a {
                position: relative;
                display: block;
                padding: 15px 15px;
                @include transition( all .2s ease-in-out);

                .badge {
                    position: absolute;
                    top: 16px;
                    right: 5px;
                }
            }

            > .icon-has-ul-h { // icon for horizontal nav
                display: none;
            }

            > .icon-has-ul { // icon for default vertical nav
                position: absolute;
                top: 18px;
                right: 15px;
            }
        }

        // sub menu
        ul {
            background-color: $sub_nav_bg;
            display: none;

            a {
                font-size: 13px;
            }

            li {
                position: relative;
                padding: 0;
                &:hover,
                &.active {
                    a {
                        color: $brand-primary;
                    }
                }
                &:last-child > a {
                    border-bottom: 0;
                }

                > a {
                    position: relative;
                    display: block;
                    padding: 13px 0 13px 25px;
                    @include transition( all .2s ease-in-out);
                    &:first-child {
                        border-top: none;
                    }

                    i.fa-caret-right {
                        margin-right: 25px;
                    }

                    > .badge {
                        position: absolute;
                        right: 5px;
                        top: 12px;
                    }
                }
            }
        }
    }
}

