.ui-select {
    position: relative;
    display: inline-block;
    // overflow: hidden;
    margin: 0 0 2px 1.2%;
    width: auto;
    height: auto;
    border: 1px solid $input-border;
    border-radius: $border-radius-base;

    > select {
        z-index: 99;
        display: block;
        position: relative;
        padding: 10px 15px 10px 10px;
        min-width: 200px;
        width: 100%;
        border: none;
        outline: none;
        background: transparent;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: bold;
        text-indent: 0.01px;
        text-overflow: '';
        cursor: pointer;
        -webkit-appearance: none; // hide select arrow on webkit
        -moz-appearance: none;
    }

    // hide select arrow on IE 10+
    select::-ms-expand { display: none; }

    &:after {
        z-index: 0;
        content: "";
        position: absolute;
        right: 8%;
        top: 50%;
        color: $input-border;
        width: 0;
        margin-top: -3px;
        height: 0;
        border-top: 6px solid;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        pointer-events:none;
    }
}

// Hide select arrow in Firefox 30+, thanks http://jsbin.com/yaruh
/* Show only the native arrow */
@-moz-document url-prefix() {
    .ui-select {
        overflow: hidden;
    }
    .ui-select select {
        width: 120%;
        width: -moz-calc(100% + 3em);
        width: calc(100% + em);
    }
}
/* Firefox focus has odd artifacts around the text, this kills that. See https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-focusring */
.ui-select select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
