@use 'sass:math';
// Navbar vertical align
//
// Vertically center elements in the navbar.
// Example: an element has a height of 30px, so write out `.navbar-vertical-align(30px);` to calculate the appropriate top margin.

@mixin navbar-vertical-align($element-height) {
  margin-top: math.div(math.div($navbar-height - $element-height, 2), 2);
  margin-bottom: math.div(math.div($navbar-height - $element-height, 2), 2);
}
