
.layout-boxed {
  > dash-header > .header-container {
      .top-header {
          max-width: $boxed_layout_width;
          margin: 0 auto;
      }
  }

  > .main-container {
      max-width: $boxed_layout_width;
      margin: 0 auto;
  }
}

html {
  position: relative;
  min-height: 100%; // important!
}

// default
body>dash-root {
  position: static; //
  min-height: 100%; // important!

  > dash-header {
    > .header-container {
      &.header-fixed {
          position: fixed;
          right: 0;
          left: 0;
          z-index: 10;


      }
      .top-header {}
    }

    & + .main-container {
      padding-top: ($header_height * 2);
      @media (min-width:$screen-sm) {
          padding-top: $header_height;
      }
    }
  }

  > .main-container {
      position: static;   // important! make sure &:before position based on html
      &:before { // content container background for boxed layout
          content: " ";
          line-height: 0;
          z-index: -2; // so that it is beneath sidebar
          position: absolute;
          display: block;
          width: 100%;
          max-width: inherit; //
          top: 0;
          bottom: 0;
          background-color: $main_container_bg;
          box-shadow: 0 0 2px rgba(0,0,0,.2);
      }

      > dash-sidenav > .nav-container {
          display: none;
          z-index: 11; // should be highter than top header
          position: static;
          float: left;
          width: 220px;
          background-color: $sidebar_bg;
          border-width: 0 1px 0 0;
          border-style: solid;
          border-color: darken($sidebar_bg, 10%);
          @media (min-width:$screen-sm) {
              display: block;
          }
          &:before {
              z-index: -1; // so that it is beneath top header
              content: " ";
              line-height: 0;
              position: absolute;
              display: block;
              top: 0;
              bottom: 0;
              width: inherit;
              background-color: inherit;
              border-width: inherit;
              border-style: inherit;
              border-color: inherit;
          }
          &.nav-fixed {
              position: fixed;
              top: ($header_height * 2);
              bottom: 0;
              float: none;
              @media (min-width:$screen-sm) {
                  top: $header_height;
              }
          }


      }

      > .content-container {
          overflow: hidden;
          min-height: 100%;
          margin-left: 0;
          // .transition(none);
          @media (min-width:$screen-sm) {
              margin-left: 220px;
          }
      }

  }

}


// layout for horizontal nav
@media (min-width: $screen-sm) {
  body>dash-root {
      > .main-container {
          > dash-sidenav > .nav-container {
              &.nav-horizontal {
                  z-index: 9; // less then header, for dropdowns
                  border-width: 0 0 1px;
                  border-style: solid;
                  border-color: darken($sidebar_bg, 10%);
                  &:before {
                      border: 0;
                  }
                  &.nav-fixed {
                      border-width: 0 1px 1px 0;
                      left: 0;
                      right: 0;
                      bottom: auto;

                      #nav {
                          text-align: center;
                          > li {
                              display: inline-block;
                              float: none;
                              > a {
                                  padding: 15px 28px;
                              }
                          }
                      }

                      & + .content-container {
                          margin: 80px 0 0;
                      }
                  }
              }
          }
      }
  }
}
