.square-icon,
.round-icon {
    display: block;
    text-align: center;

    width: 60px;
    height: 60px;
    font-size: 26px;
    line-height: 60px;

    &.sm {
        width: 35px;
        height: 35px;
        font-size: 16px;
        line-height: 35px;
    }


}

.round-icon { border-radius: 50%; }

