.pagination {
    > li {
        > a {
            cursor: pointer;
        }
        > a,
        > span {
            margin-left: 1px;
        }

    }   
}