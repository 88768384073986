.page-signin,
.page-signup,
.page-forgot {

    .signin-header {
        margin-top: 50px;
        @media (min-width: $screen-sm) {
            margin-top: 150px;
        }
    }

    .logo {
        font-size: 26px;
        font-weight: normal;
        text-transform: uppercase;
        a {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .info {
        padding: 20px 0 0;
        max-width: 420px;
        margin: 0 auto 20px;

        h2 {
            font-size: 18px;
            color: $dark;
        }
    }

    // 
    .signin-body,
    .signup-body {
        border-top: 1px solid lighten($body-bg, 2%);
        padding: 20px 10px;
    }

    .form-container {
        max-width: 420px;
        margin: 10px auto;
    }

    .form-group {
        position: relative;

        > .glyphicon {
            position: absolute;
            top: 16px;
            left: 12px;
            color: $gray-light;
        }

        .input-lg {
            padding: 10px 30px;
        }
    }
}