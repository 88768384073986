// overrides Bootstrap
.panel {
    .panel-heading {
        text-transform: uppercase;
    }
    .panel-title {
        font-size: $font-size-base;
    }
}

.panel-dark {
    @include panel-variant($dark, #fff, $dark, $dark);
}

// panel box
.panel-box {
    display: table; // ensure always the same height for panel left and right
    table-layout: fixed; // ensure same width
    width: 100%;
    height: 100%;
    text-align: center;
    border: none;

    .panel-item {
        display: table-cell;
        padding: 30px;
        width: 1%;
        vertical-align: top;
        border-radius: 0;
    }
    .panel-left {
        @include border-left-radius ($border-radius-base);
    }
    .panel-right {
        @include border-right-radius ($border-radius-base);
    }

    &.info-box {
        i {
            line-height: 70px;
        }
    }

    .panel-top,
    .panel-bottom {
        display: block;
    }
    .panel-top {
        padding: 30px 20px;
        @include border-top-radius ($border-radius-base);
    }
    .panel-bottom {
        padding: 10px;
        @include border-bottom-radius ($border-radius-base);

        p {
            margin: 0;
        }
    }

    .list-justified-container {
        padding: 15px 0;
    }

    ul.list-justified {
        display: table;
        width: 100%;
        list-style: none;
        padding: 0;

        > li {
            float: none;
            display: table-cell;
            padding: 10px;
            width: 1%;
            border-right: 1px solid $gray-lighter;
            &:last-child {
                border: none;
            }

            p {
                margin: 0;
            }
        }
    }

    .panel-icon {
        display: block;
        margin: -75px auto 0;
        padding: 28px 35px 35px;
        border-radius: 50%;
        border: 10px solid $reverse;
        width: 150px;
        height: 150px;
        line-height: 80px;
        text-align: center;
        font-size: 58px;
        text-shadow: -6px 8px 5px rgba(0,0,0,0.3);
    }

}

// mini box
.mini-box {
    min-height: 120px;
    padding: 25px;
    .box-icon {
        display: block;
        float: left;
        margin: 0 15px 10px 0;
        width: 70px;
        height: 70px;
        line-height: 70px;
        vertical-align: middle;
        text-align: center;
        font-size: 35px;
        border-radius: $border-radius-base;

        &.rounded {
            border-radius: 50%;
        }
    }

    .box-info {
        p {
            margin: 0;
        }
    }
}

//
.panel-profile {
    border: none;

    .profile {
        margin: 5px 15px 5px 5px;
        border-radius: 50%;
        box-shadow: 0 0 0 5px lighten($skin, 10%), 0 0 10px 0 rgba(0,0,0,.2);

        img {
            border-radius: 50%;
        }
    }

    .list-group > li > i {
        margin-right: 10px;
        font-size: 16px;
        color: $gray-light;
        table-layout: fixed;
    }

    .list-justified-container {
        padding: 15px 0;
    }

    ul.list-justified {
        display: table;
        width: 100%;
        list-style: none;
        padding: 0;

        > li {
            float: none;
            display: table-cell;
            padding: 10px;
            width: 1%;
            border-right: 1px solid $gray-lighter;
            &:last-child {
                border: none;
            }

            p {
                margin: 0;
            }
        }
    }
}
// Optional heading
.panel-heading {
  padding: $panel-heading-padding;
  display: block;
  border-bottom: 1px solid transparent;

  > .dropdown .dropdown-toggle {
    color: inherit;
  }
}
