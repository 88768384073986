// https://github.com/zurb/responsive-tables
table.responsive {
    border: 1px solid #ddd;
}
@media only screen and (max-width: 767px) {
    
    table.responsive { 
        margin-bottom: 0;
    }
    
    .pinned { position: absolute; left: 0; top: 0; background: #fff; width: 35%; overflow: hidden; overflow-x: scroll; border-right: 1px solid #ccc; border-left: 1px solid #ccc; }
    .pinned table { 
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-right: none; border-left: none; width: 100%; 
    }
    .pinned table th, .pinned table td { white-space: nowrap; }
    .pinned td:last-child { border-bottom: 0; }
    
    div.table-wrapper { position: relative; margin-bottom: 20px; overflow: hidden; border-right: 1px solid #ccc; }
    div.table-wrapper div.scrollable { margin-left: 35%; }
    div.table-wrapper div.scrollable { overflow: scroll; overflow-y: hidden; }  
    
    table.responsive td, table.responsive th { position: relative; white-space: nowrap; overflow: hidden; }
    table.responsive th:first-child, table.responsive td:first-child, table.responsive td:first-child, table.responsive.pinned td { display: none; }
}