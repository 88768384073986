.ui-bullet {
    overflow: auto;

    .ui-bullet-icon {
        float: left;

    }

    .ui-bullet-content {
        margin-left: 75px;
        margin-bottom: 2em;
    }

    h3 {
        display: inline-block;
        border-bottom: 1px solid transparentize($text-color, .8);
        font-size: 24px;
        margin: .5em 0 .6em;
    }
}

