
// Layout
$boxed_layout_width:    1200px;
$screen-sm-max:         ($screen-sm - 1);

// Top header
$header_height:             50px;
$header_container_bg:       $dark;

$main_container_bg:         #f9f9f9;

// Sidebar
$sidebar_bg:                #f1f1f1;